import React from 'react';

import Banner from './components/Banner';
import Content from './components/Content';
import HelpButton from './components/TextHelpButton';


const App = () => {

    return (
        <div className="container mt-5">
            <Banner adSlot="1590186052" styleClass="side-banner" />
            <Content />
            <Banner adSlot="2769080947" styleClass="side-banner"/>
        </div>
    );
}

export default App;
