import React, { useState, useEffect } from 'react';
import { useDetectAdBlock } from "adblock-detect-react";
import Banner from "./Banner";

import '../App.css';


const PopupAdBlockDetection = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(true);
  const adBlockDetected = useDetectAdBlock();

  useEffect(() => {
    const handleResize = () => {
      if (isPopupVisible) {
        setIsPopupVisible(true);
      }
    }
    
    const handleFocus = () => {
      setIsPopupVisible(false);
    };

    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!isPopupVisible) {
    return null;
  }

  return (
    <>
      {adBlockDetected && 
        <div className='overlay'>
          <div className='popup'>
            <h2 className='adblock-title'>AdBlock Detected</h2>
            <p className='adblock-text'>
              Thank you for visiting this site! As an independent developer, I rely on advertising to keep this product up and operative :)
            </p>
            <p className='adblock-text'>
              You can support this site by disabling your AdBlocker or by clicking on the ad below (and then returning to this tab)</p>
            <p className='adblock-text'>
              Thank you for your understanding and support!</p>
            <Banner adSlot="8210810004" styleClass="adblock-banner" />
          </div>
        </div>}
    </>
  );
};

export default PopupAdBlockDetection;
