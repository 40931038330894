// src/HelpButton.js
import React, { useState } from 'react';
import '../App.css';


const HelpButton = ({ message }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="help-button-container">
      <button
        className="help-button"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >?</button>
      {showTooltip && <div className="tooltip">{message}</div>}
    </div>
  );
};

export default HelpButton;
