import React, { useState } from 'react';
import HelpButton from '../TextHelpButton';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import '../../App.css';


const Form = ({ reducedImageUrl, setReducedImageUrl, drawingUrl, setDrawingUrl, processing, setProcessing }) => {

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [preview, setPreview] = useState(null);
    const [numColors, setNumColors] = useState(5);
    const [blurDiameter, setBlurDiameter] = useState(9);
    const [blurStrength, setBlurStrength] = useState(75);
    const [noiseThreshold, setNoiseThreshold] = useState(150);
    const [numFontSize, setNumFontSize] = useState(20);
    const [error, setError] = useState('');
    const isProd = process.env.NODE_ENV === "production";
    
    
    const handleFileChange = (event) => {
        setReducedImageUrl(null);
        setDrawingUrl(null);

        const file = event.target.files[0];
        setFile(file);
        setFileName(event.target.files[0].name);

        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        } else {
            setPreview(null);
        }
    };

    const handleUpload = async () => {

        if (file) {
            setProcessing(true);
            setError('');

            const formData = new FormData();
            formData.append('image', file);
            formData.append('num_colors', numColors);
            formData.append('blurring_diameter', blurDiameter);
            formData.append('blurring_strength', blurStrength);
            formData.append('numbers_font_size', numFontSize);

            try {
                const response = await fetch(`${isProd ? '' : 'http://localhost:8000'}/transform`, {
                    method: 'POST',
                    body: formData
                });

                if (!response.ok) {
                    throw new Error('Error uploading file');
                }
    
                const data = await response.json();
                
                drawingUrl = 'data:image/jpeg;base64,' + data.drawing;
                reducedImageUrl = 'data:image/jpeg;base64,' + data.reduced_image;

                setReducedImageUrl(reducedImageUrl);
                setDrawingUrl(drawingUrl);

            } catch (error) {
                console.error('Error:', error);
                setError('Error processing the image. Please try again.');
            } finally {
                setProcessing(false);
            }
        }
    };

    // JavaScript function to trigger the file input click event
    function triggerFileInput() {
        document.getElementById('fileInput').click();
    }

    return (
        <>
            <div className="form-container">
                <form>
                    <div className='form-view'>
                        <div style={{ display: 'flex', flex: 1}}>
                            <div style={{ flex: 1 }}>
                                <div className="form-group mt-3" style={{ padding: preview ? '0px 0px 20px 0px' : '0px 0px 0px 0px' }}>
                                    <input type="file" id="fileInput" className="form-control-file hidden-file-input" onChange={handleFileChange} accept="image/*" />
                                    <button type="button" onClick={triggerFileInput} className="btn">Choose File</button>
                                    <label><span className='file-name'>{fileName}</span></label>
                                </div>
                                { preview &&  <div className="form-group mt-3">
                                    <div className='slider'>
                                        <label htmlFor="numColors" className='slider-title'>Number of Colors</label>
                                        <HelpButton message="The number of colors of the final drawing" />
                                        <label><span className='slider-number'>{numColors}</span></label>  
                                    </div>
                                    <input type="range" className='slider-length' id="numColors" min="2" max="25" value={numColors} onChange={(e) => setNumColors(e.target.value)} />
                                </div> }
                                { preview && <div className="form-group mt-3">
                                    <div className='slider'>
                                        <label htmlFor="blurDiameter" className='slider-title'>Blurring Diameter</label>
                                        <HelpButton message="Affects the image smoothing. The bigger the value the less noise in the drawing (especially in HD images)" />
                                        <label><span className='slider-number'>{blurDiameter}</span></label>
                                    </div>
                                    <input type="range" className='slider-length' id="blurDiameter" min="0" max="50" value={blurDiameter} onChange={(e) => setBlurDiameter(e.target.value)} />
                                </div>}
                                { preview && <div className="form-group mt-3">
                                    <div className='slider'>
                                        <label htmlFor="blurStrength" className='slider-title'>Blurring Strength</label>
                                        <HelpButton message="Affects the image smoothing. The bigger the value the less noise in the drawing (especially in HD images)" />
                                        <label><span className='slider-number'>{blurStrength}</span></label>
                                    </div>
                                    <input type="range" className='slider-length' id="blurStrenth" min="0" max="120" value={blurStrength} onChange={(e) => setBlurStrength(e.target.value)} />
                                </div>}
                                { preview && <div className="form-group mt-3">
                                    <div className='slider'>
                                        <label htmlFor="numFontSize" className='slider-title'>Numbers Font Size</label>
                                        <HelpButton message="The size of the legend numbers in the drawing" />
                                        <label><span className='slider-number'>{numFontSize}</span></label>
                                    </div>
                                    <input type="range" className='slider-length' id="numFontSize" min="4" max="30" value={numFontSize} onChange={(e) => setNumFontSize(e.target.value)} />
                                </div>}
                            </div>
                        </div>
                        { preview && <div className='preview-image'><img src={preview} style={{ maxWidth: '100%' }} alt=""/></div>}
                    </div>
                    {error && <div className="alert alert-danger mt-3">{error}</div>} {/* TODO: Prettify */}
                </form>
                {preview && <div style={{ marginLeft: 'auto', marginTop: '15px' }}>
                    <button type="button" className="btn" onClick={handleUpload} disabled={processing}>
                        {processing ? 'Processing...' : 'Process'}
                    </button>
                </div>}
            </div>
        </>
    );
}

export default Form;
