import React from 'react';
import DoubleCheckButton from './DoubleCheckButton';
import PDFDocument from './PDFDocument';
import { PDFViewer } from '@react-pdf/renderer';

import '../../App.css';


const DownloadPage = ({
  reducedImageUrl,
  setReducedImageUrl,
  setDownloaded,
  drawingUrl,
  orientation
}) => {

    return (
      <div style={{ width: '90%'}}>
          <div style={{ marginBottom: '20px' }}>
              <DoubleCheckButton setDownloaded={setDownloaded} setReducedImageUrl={setReducedImageUrl}/>
          </div>
          <div style={{ width: '100%', height: '100vh', transform: 'scale(1)' }}>
              <PDFViewer width="100%" height="100%">
                    <PDFDocument 
                        reduced_image={reducedImageUrl}
                        drawing={drawingUrl}
                        orientation={orientation}
                    />
              </PDFViewer>
          </div>
      </div>
    );
}

export default DownloadPage;
