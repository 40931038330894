import React from 'react';
import { Page, Document, StyleSheet, Image } from '@react-pdf/renderer';

import '../../App.css';


const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '95%',
    height: 'auto',
    marginVertical: 15,
  },
  rotatedImage: {
    width: '95%', // Adjust the width as needed to fit the page
    height: 'auto',
    marginVertical: 15,
    transform: 'rotate(90deg)', // Rotate the image
  },
  frame: {
    borderWidth: 1,
    borderColor: 'black',
    padding: 10,
  },
});

// Create Document Component
const PDFDocument = ({ reduced_image, drawing, orientation }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image src={reduced_image} style={orientation === 'landscape' ? styles.rotatedImage : styles.image} />
    </Page>
    <Page size="A4" style={styles.page}>
      <Image src={drawing} style={orientation === 'landscape' ? styles.rotatedImage : styles.image} />
    </Page>
  </Document>
);

export default PDFDocument;