import React from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import DownloadButton from '../download/DownloadButton';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import '../../App.css';

import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "EUR",
    intent: "capture",
};

const ImagesPreview = ({
    reducedImageUrl, drawingUrl,
    processing, setProcessing,
    setDownloaded 
}) => {

    return (
        <div>
            <div className="image-container">
                <div>
                    <InnerImageZoom src={reducedImageUrl} zoomSrc={reducedImageUrl} zoomPreload={true} />
                </div>
                <div>
                    <InnerImageZoom src={drawingUrl} zoomSrc={drawingUrl} zoomPreload={true} />
                </div>
            </div>
            <PayPalScriptProvider options={initialOptions}>
                <DownloadButton 
                    reduced_image={reducedImageUrl} drawing={drawingUrl}
                    processing={processing} setProcessing={setProcessing}
                    setDownloaded={setDownloaded} 
                />
            </PayPalScriptProvider>
        </div>
    );
}

export default ImagesPreview;
