import React from 'react';
import ImagesPreview from './ImagesPreview';
import Form from './Form';

import '../../App.css';


const StudioPage = ({ 
  reducedImageUrl, setReducedImageUrl,
  drawingUrl, setDrawingUrl,
  processing, setProcessing,
  downloaded, setDownloaded
}) => {

  return (
    <>
      <Form 
          reducedImageUrl={reducedImageUrl} setReducedImageUrl={setReducedImageUrl} 
          drawingUrl={drawingUrl} setDrawingUrl={setDrawingUrl}
          processing={processing} setProcessing={setProcessing}
      />
      { reducedImageUrl && (
          <ImagesPreview 
              reducedImageUrl={reducedImageUrl} drawingUrl={drawingUrl}
              processing={processing} setProcessing={setProcessing}
              setDownloaded={setDownloaded}
          />)
      }
      {processing && (
          <div className="overlay">
              <div className="loading-icon"></div>
          </div>
      )}
    </>
  );

}

export default StudioPage;
