import React, { useEffect, useState } from 'react';
import logo from "../logo.png";
import StudioPage from './studio/StudioPage';
import DownloadPage from './download/DownloadPage';
import AdBlockDetection from './AdBlockDetection';

import '../App.css';


const Content = () => {

    const [reducedImageUrl, setReducedImageUrl] = useState(null);
    const [drawingUrl, setDrawingUrl] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [downloaded, setDownloaded] = useState(false);
    const [orientation, setOrientation] = useState(null);

    useEffect(() => {
        const handleRightClick = (event) => {
            event.preventDefault();
        };
        
        window.addEventListener('contextmenu', handleRightClick);

        const img = new Image();
        img.onload = function() {
            setOrientation(this.width > this.height ? 'landscape' : 'portrait');
        };
        img.src = reducedImageUrl;
        
        return () => {
            window.removeEventListener('contextmenu', handleRightClick);
        };
    }, [reducedImageUrl, drawingUrl]);

    return (
      <div className="content mt-5">
        <AdBlockDetection />
        <div className="header">
            <img src={logo} className="logo" alt="logo" />
        </div>
        { !downloaded ? (
            <StudioPage
                reducedImageUrl={reducedImageUrl} setReducedImageUrl={setReducedImageUrl} 
                drawingUrl={drawingUrl} setDrawingUrl={setDrawingUrl}
                processing={processing} setProcessing={setProcessing}
                downloaded={downloaded} setDownloaded={setDownloaded}
            />
        ) : (
            <DownloadPage 
                reducedImageUrl={reducedImageUrl} setReducedImageUrl={setReducedImageUrl}
                setDownloaded={setDownloaded} drawingUrl={drawingUrl} orientation={orientation}
            />
        )}
    </div>);
}

export default Content;
