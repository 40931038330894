import React from "react";
import Adsense from "react-adsense";

import '../App.css';


const Banner = ({ adSlot, styleClass }) => {

    const googleAdSenseClient = process.env.REACT_APP_GOOGLE_ADSENSE_CLIENT;
    const isProd = false; // process.env.NODE_ENV === "production";

    const handleMockAdClick = () => {
        window.open("https://www.example.com", "_blank");
    };

    return (
        <div className={styleClass}>
            {isProd ? (
                <Adsense.Google
                    client={googleAdSenseClient}
                    slot={adSlot}
                    style={{ display: "block" }}
                    format="auto"
                />
            ) : (
                <div onClick={handleMockAdClick}>
                    <p>Mock Ad - Click here!</p>
                </div>
            )}
        </div>
    );
};

export default Banner;
