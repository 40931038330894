import React, { useState } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

import '../../App.css';


const DownloadButton = ({
    reduced_image, drawing,
    processing, setProcessing,
    setDownloaded
}) => {

    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const [currency, setCurrency] = useState(options.currency);
    const isProd = process.env.NODE_ENV === "production";

    const onCreateOrder = (data,actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: "1.49",
                    },
                },
            ],
        });
    }

    const onApproveOrder = (data,actions) => {
        return actions.order.capture().then((details) => {
            alert(`Payment complete! Please be patient while we prepare your download.`);
            downloadDocument();
        });
    }
    
    const downloadDocument = async () => {        
        setProcessing(true);

        const formData = new FormData();
        formData.append('reduced_image', reduced_image);
        formData.append('drawing', drawing);

        try {
            const response = await fetch(`${isProd ? '' : 'http://localhost:8000' }/download`, {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error('Error downloading file');
            }
            setDownloaded(true);

        } catch (error) {
            console.error('Error:', error);
        } finally {
            setProcessing(false);
        }
    };
    
    return (
        <div className="paypal-buttons-container">
            <div className="message">
                Download the images for just:
            </div>
            <div className="amount">
                1.49 €
            </div>
            <PayPalButtons
                style={{ layout: "vertical" }}
                createOrder={(data, actions) => onCreateOrder(data, actions)}
                onApprove={(data, actions) => onApproveOrder(data, actions)}
            />
            <button type="button" className="btn" onClick={downloadDocument}>Download</button>
        </div>
    );
}

export default DownloadButton;