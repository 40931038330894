import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import '../../App.css';


const DoubleCheckButton = ({ setDownloaded, setReducedImageUrl }) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    // Push a new entry into the history stack.
    window.history.pushState({ page: "doubleCheck" }, "");

    // Listen for the popstate event, which indicates the user has clicked the back button.
    const handlePopState = (event) => {
      event.preventDefault();
      setOpen(true); // Show the dialog
    };

    window.addEventListener('popstate', handlePopState);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const handleConfirm = () => {
    setDownloaded(false);
    setReducedImageUrl(null);
    window.history.back(); // Go back to the previous page if the user confirms
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // Replace the current state with a new state to prevent the dialog from showing again if the user navigates forward.
    window.history.replaceState({}, "");
  };

  return (
    <React.Fragment>
        <button type="button" className="btn-back" onClick={handleClickOpen}>Back</button>
        <Dialog open={open} onClose={handleClose}
            aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        If you go back to editing a new image you won't be able to come back to this page
                        and download the current drawing. Are you sure you want to continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleConfirm}>Yes</Button>
                </DialogActions>
        </Dialog>
    </React.Fragment>
  );
}

export default DoubleCheckButton;
